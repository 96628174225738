import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/v2/templates/Layout';
import { Section } from '../components/v2/templates/Section';
import useStoryblok from '../lib/storyblok';
import Bloks from '../components/atoms/Bloks';
import { StoryblokPage } from '../types';

const OurTech: StoryblokPage = ({ location, data }) => {
  let story = useStoryblok(data.storyblokEntry, location);
  const { content } = story;

  return (
    <Layout location={location} headerData={content.header} footerData={content.footer}>
      <Bloks bloks={content.SEO} />
      <Section curved hero noGrid curveColor="tertiary">
        <Bloks bloks={content.hero} />
      </Section>

      <Bloks bloks={content.sections} />
    </Layout>
  );
};

export default OurTech;

export const query = graphql`
  query OurTech {
    storyblokEntry(full_slug: { eq: "our-tech" }) {
      content
      name
    }
  }
`;
